import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { HR } from '@system'
import { TableRow, TableCell } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  hrTableCell: {
    padding: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '',
      width: '100%',
      margin: 'auto',
    },
  },
}))

const HorizontalRule = ({ blok }) => {
  const classes = useStyles()
  const { variant } = blok

  const TableRowWrapper = ({ children }) =>
    variant === 'table-row' ? (
      <TableRow>
        <TableCell colSpan={20} className={classes.hrTableCell}>
          {children}
        </TableCell>
      </TableRow>
    ) : (
      children
    )

  return (
    <SbEditable content={blok}>
      <TableRowWrapper>
        <HR {...blok} />
      </TableRowWrapper>
    </SbEditable>
  )
}

export default HorizontalRule
